import * as React from "react"
import './404.css'

// markup
const NotFoundPage = () => {
  return (
    <div id="main">
    	<div class="fof">
        		<h1>Error 404</h1>
    	</div>
</div>
  )
}

export default NotFoundPage
